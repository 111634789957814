import { z } from 'zod'
import { baseModelShape } from '../baseModel'
import { campaignShape } from '../campaign'
import { userShape } from '../users'

export const leadCreateShape = {
  campaign: z.string().uuid(),
  fundraiser: z.string().uuid().nullable().optional(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  mobilePhone: z.string().nullable().optional(),
  dateOfBirth: z.string(),
  addressStreet: z.string().optional(),
  addressDetail: z.string().nullable().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  postalCode: z.number().or(z.string()).optional(),
}

export const leadShape = {
  ...baseModelShape,
  campaign: z.object(campaignShape),
  fundraiser: z.object(userShape).nullable().optional(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email().nullable(),
  mobilePhone: z.string().nullable(),
  dateOfBirth: z.string(),
  addressStreet: z.string().optional(),
  addressDetail: z.string().nullable().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  postalCode: z.number().or(z.string()).optional(),
  latitude: z.string().nullable(),
  longitude: z.string().nullable(),
}

export const leadFiltersShape = {
  ordering: z.string().optional(),
  campaign: z.string(),
  dateRangeStart: z.date().or(z.string()),
  dateRangeEnd: z.date().or(z.string()),
  fundraiser: z.string(),
}
