<template>
  <form class="space-y-6 px-4 py-8 sm:px-8 bg-white" @submit.prevent="submitFundraiser">
    <div>
      <h3 class="title text-sm font-semibold leading-3 text-gray-900">Add New Fundraiser</h3>
    </div>
    <fieldset class="grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-4 text-sm">
      <!-- First / Last name -->
      <div class="sm:col-span-2 col-span-4">
        <InputField
          :labelSize="'small'"
          :inputTextSize="'small'"
          v-model:value="form.firstName.value"
          :errors="form.firstName.errors"
          @blur="form.firstName.validate()"
          label="First name"
          type="text"
          text="fundraiser-first-name"
          id="fundraiser-first-name"
          placeholder="Enter first name..."
        />
      </div>
      <div class="sm:col-span-2 col-span-4">
        <InputField
          :labelSize="'small'"
          :inputTextSize="'small'"
          v-model:value="form.lastName.value"
          :errors="form.lastName.errors"
          @blur="form.lastName.validate()"
          label="Last name"
          type="text"
          text="fundraiser-last-name"
          id="fundraiser-last-name"
          placeholder="Enter last name..."
        />
      </div>
    </fieldset>

    <div class="grid grid-cols-1 sm:grid-cols-4 gap-y-8 gap-x-6 border-t border-gray-900/10 pt-6">
      <!-- Email -->
      <div class="col-span-4">
        <InputField
          :labelSize="'small'"
          :inputTextSize="'small'"
          v-model:value="form.email.value"
          :errors="form.email.errors"
          @blur="form.email.validate()"
          label="Email"
          type="email"
          text="fundraiser-email"
          id="fundraiser-email"
          placeholder="example@email.com"
          autocomplete="email"
        />
      </div>
    </div>
    <div class="flex w-full ml-auto space-x-6">
      <button type="submit" class="btn--primary--small w-full" id="fundraiser-info-submit">
        Submit
      </button>
    </div>
  </form>
</template>

<script>
import { ref, watch } from 'vue'
import InputField from '@/components/inputs/InputField'
import { InviteFundraiserForm } from '@/services/users/'

export default {
  name: 'FundraiserFormInfo',
  components: {
    InputField,
  },
  props: {
    clearForm: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['submitForm'],
  setup(props, { emit }) {
    const form = ref(new InviteFundraiserForm())

    watch(() => {
      if (props.clearForm) {
        form.value.firstName.value = ''
        form.value.firstName.errors = []
        form.value.lastName.value = ''
        form.value.lastName.errors = []
        form.value.email.value = ''
        form.value.email.errors = []
      }
    })

    const submitFundraiser = () => {
      const unwrappedForm = form.value
      unwrappedForm.validate()

      if (!unwrappedForm.isValid) return

      // Emit the event to the parent component
      emit('submitForm', form.value.formVal)
    }

    return {
      form,
      submitFundraiser,
    }
  },
}
</script>
