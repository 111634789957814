import axiosInstance from '../AxiosClient'
import { leadShape, leadCreateShape, leadFiltersShape } from './models'
import {
  createApi,
  createCustomServiceCall,
  createCollectionManager,
  Pagination,
} from '@thinknimble/tn-models'
import fileDownload from 'js-file-download'

const LEADS_ENDPOINT = '/leads/'

const customCreate = createCustomServiceCall(
  {
    inputShape: leadCreateShape,
    outputShape: leadShape,
  },
  async ({ client, utils, input }) => {
    const { fundraiser, ...data } = input
    let formattedData = utils.toApi(data)
    const params = fundraiser ? `?fundraiser=${fundraiser}` : ''
    const res = await client.post(`${LEADS_ENDPOINT}${params}`, formattedData)
    return utils.fromApi(res.data)
  },
)

const generateExport = createCustomServiceCall(
  {
    inputShape: leadFiltersShape,
  },
  async ({ client, utils, input }) => {
    const { ...data } = input
    let formattedData = utils.toApi(data)

    client
      .post(`${LEADS_ENDPOINT}generate_leads_export/`, formattedData, {
        responseType: 'blob', // important
      })
      .then((response) => {
        fileDownload(response.data, 'leads_export.csv')
      })
  },
)

export const LeadApi = createApi(
  {
    baseUri: LEADS_ENDPOINT,
    client: axiosInstance,
    models: {
      entity: leadShape,
      create: leadCreateShape,
      extraFilters: leadFiltersShape,
    },
  },
  { customCreate, generateExport },
)

export const leadFunctions = () => {
  const leadFilters = {
    ordering: '-givaroo_id',
    campaign: '',
    dateRangeStart: '',
    dateRangeEnd: '',
    fundraiser: '',
  }

  const leadCollection = createCollectionManager({
    fetchList: LeadApi.list,
    filters: leadFilters,
    pagination: new Pagination(),
  })

  return {
    leadCollection,
    leadFilters,
  }
}
