import Form, {
  FormField,
  RequiredValidator,
  EmailValidator,
  MinimumValueValidator,
  MaximumValueValidator,
} from '@thinknimble/tn-forms'

export class LeadForm extends Form {
  static firstName = new FormField({ validators: [new RequiredValidator()] })
  static lastName = new FormField({ validators: [new RequiredValidator()] })
  static birthDay = new FormField({
    validators: [
      new RequiredValidator(),
      new MinimumValueValidator({ min: 1, message: 'Please enter a minimum value of 1' }),
      new MaximumValueValidator({ max: 31, message: 'Please enter a max value of 31' }),
    ],
  })
  static birthMonth = new FormField({ validators: [new RequiredValidator()] })
  static birthYear = new FormField({ validators: [new RequiredValidator()] })
  static addressStreet = new FormField()
  static addressDetail = new FormField()
  static city = new FormField()
  static state = new FormField()
  static postalCode = new FormField()
  static email = new FormField({ validators: [new RequiredValidator(), new EmailValidator()] })
  static mobilePhone = new FormField({ validators: [new RequiredValidator()] })

  get formVal() {
    return {
      ...this.value,
      dateOfBirth: `${this.value.birthYear}-${this.value.birthMonth}-${this.value.birthDay}`,
    }
  }
}
