<template>
  <DashboardContainer>
    <Spinner v-if="loading" class="mt-24" />
    <template v-else>
      <!-- Fundraiser list (mobile) -->
      <div class="md:hidden">
        <ul
          role="list"
          class="mt-2 rounded-lg divide-y divide-gray-200 bg-white overflow-hidden shadow"
        >
          <li v-for="(fundraiser, key) in fundraisers.list" :key="key">
            <div class="px-4 pt-4 truncate">
              <strong class="block truncate">{{ fundraiser.fullName }}</strong>
              <div class="flex flex-col">
                <div class="flex flex-row justify-between">
                  <p>Donations Received:</p>
                  <p>{{ '$' + getAmountFromDonations(fundraiser) }}</p>
                </div>
                <div class="flex flex-row justify-between mb-2">
                  <p># of Leads Signed Up:</p>
                  <p>{{ getNumberOfSignedUpLeads(fundraiser) }}</p>
                </div>
                <div class="flex flex-row justify-between mb-2">
                  <p>Status:</p>
                  <button :class="['btn--table']" @click="toggleStatus(fundraiser)">
                    {{ fundraiser.isActive ? 'Inactivate' : 'Activate' }}
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="mt-5 rounded-lg divide-y divide-gray-200 bg-white overflow-hidden shadow">
          <FundraiserFormInfo @submitForm="createFundraiser" :clearForm="clearForm" />
        </div>
      </div>
      <!-- Fundraiser list (desktop) -->
      <div class="hidden md:block">
        <div class="mt-2 flex flex-col">
          <h2 class="subheading text-sm font-bold">Fundraisers</h2>
          <div class="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead>
                <tr class="bg-gray-50 rounded-tl-lg rounded-tr-lg">
                  <th
                    scope="col"
                    class="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="bg-gray-50 px-6 py-3 text-left text-sm text-center font-semibold text-gray-900"
                  >
                    Donations Received
                  </th>
                  <th
                    scope="col"
                    class="bg-gray-50 px-6 py-3 text-left text-sm text-center font-semibold text-gray-900"
                  >
                    Number of Leads Signed Up
                  </th>
                  <th
                    scope="col"
                    class="bg-gray-50 px-6 py-3 text-left text-sm text-center font-semibold text-gray-900"
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="(fundraiser, key) in fundraisers.list" :key="key">
                  <td class="whitespace-nowrap py-4 px-6 text-sm text-gray-900">
                    {{ fundraiser.fullName }}
                  </td>
                  <td class="whitespace-nowrap py-4 px-6 text-sm text-center text-gray-900">
                    {{ '$' + getAmountFromDonations(fundraiser) }}
                  </td>
                  <td class="whitespace-nowrap py-4 px-6 text-sm text-center text-gray-900">
                    {{ getNumberOfSignedUpLeads(fundraiser) }}
                  </td>
                  <td class="whitespace-nowrap py-4 px-6 text-sm text-center text-gray-900">
                    <button :class="['btn--table']" @click="toggleStatus(fundraiser)">
                      {{ fundraiser.isActive ? 'Inactivate' : 'Activate' }}
                    </button>
                  </td>
                </tr>
                <tr class="bg-gray-50 rounded-tl-lg rounded-tr-lg">
                  <td
                    class="bg-gray-50 px-6 py-3 text-left text-sm text-center font-bold text-gray-900"
                  >
                    Totals
                  </td>
                  <td
                    class="whitespace-nowrap py-4 px-6 text-sm text-center text-gray-900 font-bold"
                  >
                    {{ '$' + getTotalDonationsAmount() }}
                  </td>
                  <td
                    class="whitespace-nowrap py-4 px-6 text-sm text-center text-gray-900 font-bold"
                  >
                    {{ getTotalNumberOfSignedUpLeads() }}
                  </td>
                  <td></td>
                  <!-- Empty cell for the status column in the totals row -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="mt-5 flex flex-col">
          <div class="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
            <FundraiserFormInfo @submitForm="createFundraiser" :clearForm="clearForm" />
          </div>
        </div>
      </div>
      <div class="hidden md:block mt-12"></div>
    </template>
    <Modal
      headerText="Fundraiser created!"
      ctaText="Close and Reset Form"
      :modalOpen="successModalOpen"
      @closeModal="toggleSuccessModal"
      :showSuccessIcon="true"
    />
    <Modal
      headerText="Fundraiser submission failed :("
      ctaText="Close and Try Again"
      :subheaderText="'Reason: ' + fundraiserFailureMessage"
      :modalOpen="failureModalOpen"
      @closeModal="toggleFailureModal"
      :showFailureIcon="true"
    />
  </DashboardContainer>
</template>

<script>
import { useStore } from 'vuex'
import { onBeforeMount, ref, triggerRef } from 'vue'
import DashboardContainer from '@/components/DashboardContainer.vue'
import Modal from '@/components/Modal'
import Spinner from '@/components/Spinner'
import { ArrowRightIcon, ChevronRightIcon } from '@heroicons/vue/20/solid'
import { donationFunctions } from '@/services/donations/'
import { fundraiserFunctions, UserApi } from '@/services/users/'
import { leadFunctions } from '@/services/leads/'
import FundraiserFormInfo from '@/components/FundraiserFormInfo'

export default {
  name: 'FundraiserList',
  components: {
    DashboardContainer,
    Modal,
    Spinner,
    ChevronRightIcon,
    ArrowRightIcon,
    FundraiserFormInfo,
  },
  setup() {
    const store = useStore()
    const userToken = store.getters.user.token
    const { fundraiserCollection } = fundraiserFunctions()
    const fundraisers = ref(fundraiserCollection)
    const { donationCollection } = donationFunctions()
    const donations = ref(donationCollection)
    const { leadCollection } = leadFunctions()
    const leads = ref(leadCollection)
    const loading = ref(false)
    const successModalOpen = ref(false)
    const failureModalOpen = ref(false)
    let fundraiserFailureMessage = ref('')
    let clearForm = ref(false)

    onBeforeMount(async () => {
      loading.value = true
      await fundraisers.value.refresh().catch((error) => {
        alert(error)
      })
      await donations.value.refresh().catch((error) => {
        alert(error)
      })
      await leads.value.refresh().catch((error) => {
        alert(error)
      })
      triggerRef(fundraisers)
      triggerRef(donations)
      triggerRef(leads)
      loading.value = false
    })

    const getFundraiserDonations = (fundraiser) => {
      return donations.value.list.filter((donation) => donation.fundraiser === fundraiser.id)
    }

    const getAmountFromDonations = (fundraiser) => {
      let donations = getFundraiserDonations(fundraiser)
      return donations.map((donation) => donation.amount).reduce((sum, a) => sum + a, 0)
    }

    const getNumberOfSignedUpLeads = (fundraiser) => {
      return leads.value.list.filter((lead) => lead.fundraiser.id === fundraiser.id).length
    }

    const getTotalDonationsAmount = () => {
      return donations.value.list.map((donation) => donation.amount).reduce((sum, a) => sum + a, 0)
    }

    const getTotalNumberOfSignedUpLeads = () => {
      return leads.value.list.length
    }

    const createFundraiser = async (fundraiserData) => {
      try {
        await UserApi.csc.inviteFundraiser(fundraiserData)
        toggleSuccessModal()
      } catch (e) {
        handleFailure('Failed to create fundraiser', e)
      } finally {
        await fundraisers.value.refresh().catch((error) => {
          alert(error)
        })
      }
      triggerRef(fundraisers)
    }

    const handleFailure = (label, error) => {
      console.log(label, error)
      fundraiserFailureMessage.value = error.message
      toggleFailureModal()
    }

    const toggleSuccessModal = () => {
      successModalOpen.value = !successModalOpen.value
      !successModalOpen.value ? (clearForm.value = true) : (clearForm.value = false)
    }

    // Display failure modal if the fundraiser info fails to create
    const toggleFailureModal = () => {
      failureModalOpen.value = !failureModalOpen.value
    }

    const toggleStatus = async (fundraiser) => {
      try {
        if (fundraiser.campaigns && fundraiser.campaigns.length > 0) {
          alert('Fundraiser must be removed from all campaign assignments before retiring.')
          return
        }
        const updatedStatus = !fundraiser.isActive
        const payload = {
          id: fundraiser.id,
          is_active: updatedStatus,
        }
        await UserApi.csc.customUpdate(payload)
        await fundraisers.value.refresh().catch((error) => {
          alert(error)
        })
        triggerRef(fundraisers)
      } catch (error) {
        console.error('Failed to update status', error)
        alert('Failed to update status')
      }
    }

    return {
      fundraisers,
      userToken,
      loading,
      donations,
      successModalOpen,
      failureModalOpen,
      fundraiserFailureMessage,
      clearForm,
      getAmountFromDonations,
      getNumberOfSignedUpLeads,
      getTotalDonationsAmount,
      getTotalNumberOfSignedUpLeads,
      createFundraiser,
      toggleSuccessModal,
      toggleFailureModal,
      toggleStatus,
    }
  },
}
</script>
